html {
  font-size: 18px;
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: "Roboto" !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  margin: 0;
  font-family: "Roboto" !important;
}

.text_white {
  color: white !important;
}

.fs_14 {
  font-size: 14px;
}

.header {
  background-color: black;
}

.bg-black {
  background: black;
}

.custom-dropdown-wrapper {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 5px;
  padding-left: 15px;
  border-radius: 5px;
  border: 1px solid black;
  background-color: white;
  cursor: pointer;
}

.custom-dropdown-wrapper img {
  height: 10px;
  width: 10px;
  position: absolute;
  top: 50%;
  right: 15px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.custom-dropdown-wrapper .items {
  overflow: hidden;
  position: absolute;
  border-radius: 5px;
  top: calc(100% + 2px);
  left: 0;
  opacity: 0;
  -webkit-transform: translateY(15px);
          transform: translateY(15px);
  -webkit-transition: 0.5s;
  transition: 0.5s;
  width: 100%;
  z-index: 99;
}

.custom-dropdown-wrapper .items .item {
  background-color: black;
  padding: 8px;
  border: 1px solid transparent;
  padding-left: 15px;
  color: white;
  display: block;
}

.custom-dropdown-wrapper .items .item:hover {
  background-color: rgba(0, 0, 0, 0.87);
}

.custom-dropdown-wrapper.open .items {
  opacity: 1 !important;
  -webkit-transform: translateY(5px) !important;
          transform: translateY(5px) !important;
}

a:hover {
  text-decoration: none !important;
}

.boxes {
  border-radius: 5px;
  padding: 7px;
  background-color: black;
}

.boxes .type {
  font-size: 1.1rem;
  font-weight: 500;
}

.boxes .number {
  font-weight: 800;
}

.single-machine-dougnut {
  overflow: hidden;
  width: 200px;
  max-width: 300px;
  margin-left: 15px;
  margin-right: 15px;
  display: inline-block;
  position: relative;
}

.single-machine-dougnut p {
  position: absolute;
  top: 50%;
  font-weight: 700;
  font-size: 16px;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  max-width: 60%;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.line-chart {
  position: relative;
}

.line-chart .bg {
  position: absolute;
  height: calc(100% - 32px);
  width: 100%;
  bottom: 28px;
  left: 0;
  z-index: 1;
}

.line-chart canvas {
  z-index: 999;
}

.pos-z {
  position: relative;
  z-index: 999;
}

.header-links a {
  margin-right: 10px;
  color: white;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.header-links a:hover {
  color: grey;
}

.image-wrapper {
  position: relative;
  padding-top: 100%;
}

.image-wrapper img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.loader-wrapper {
  position: absolute;
  background-color: black;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  opacity: 0;
  z-index: -1;
}

.loader-wrapper.show {
  opacity: 1;
  z-index: 999999;
}

.switch-wrapper {
  position: relative;
}

.switch-wrapper > div {
  position: a bsolute;
  width: 100%;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.switch-wrapper {
  position: relative;
}

.switch-wrapper > div {
  position: absolute;
}

.route-wrapper {
  position: relative;
}

.route-wrapper > div {
  position: absolute;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  -webkit-transition: opacity 250ms ease-in;
  transition: opacity 250ms ease-in;
}

.line {
  height: 3px;
  width: 100%;
  display: block;
  background-color: black;
  margin-bottom: 15px;
}

ol {
  padding-left: 15px;
}

ol li {
  padding-left: 7.5px;
  padding-bottom: 15px;
}

.single-dianostics-item img {
  height: 75px;
  width: 75px;
  -o-object-fit: contain;
     object-fit: contain;
  margin-right: 15px;
  display: inline-block;
}

.single-dianostics-item p {
  display: inline-block;
}

@media (max-width: 600px) {
  html {
    font-size: 16px;
  }
  h1 {
    font-size: 2rem;
  }
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  -webkit-animation: lds-dual-ring 1.2s linear infinite;
          animation: lds-dual-ring 1.2s linear infinite;
}

@-webkit-keyframes lds-dual-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes lds-dual-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.text-gray {
  color: gray;
}

.table tr {
  border-color: transparent !important;
}

.table td,
.table th {
  border: 1px solid black !important;
  padding: 7.5px;
  text-align: center;
}

.table th {
  width: 30px !important;
  padding: 7.5px 0px;
  border-color: transparent;
  color: black !important;
}

.table .numbers td {
  border-left-color: transparent;
  border-right-color: transparent;
  border-top-color: transparent;
  color: black !important;
}
