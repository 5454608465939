html {
  font-size: 18px;
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: "Roboto" !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  margin: 0;
  font-family: "Roboto" !important;
}

.text_white {
  color: white !important;
}

.fs_14 {
  font-size: 14px;
}

.header {
  background-color: black;
}

.bg-black {
  background: black;
}

.custom-dropdown-wrapper {
  position: relative;
  display: flex;
  padding: 5px;
  padding-left: 15px;
  border-radius: 5px;
  border: 1px solid black;
  background-color: white;
  cursor: pointer;
  img {
    height: 10px;
    width: 10px;
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
  }
  .items {
    overflow: hidden;
    position: absolute;
    border-radius: 5px;
    top: calc(100% + 2px);
    left: 0;
    opacity: 0;
    transform: translateY(15px);
    transition: 0.5s;
    width: 100%;
    z-index: 99;

    .item {
      background-color: black;
      padding: 8px;
      border: 1px solid transparent;
      padding-left: 15px;
      color: white;
      display: block;
      &:hover {
        background-color: rgba(0, 0, 0, 0.87);
      }
    }
  }
  &.open .items {
    opacity: 1 !important;
    transform: translateY(5px) !important;
  }
}

a:hover {
  text-decoration: none !important;
}

.boxes {
  border-radius: 5px;
  padding: 7px;
  background-color: black;
  .type {
    font-size: 1.1rem;
    font-weight: 500;
  }
  .number {
    font-weight: 800;
  }
}
.single-machine-dougnut {
  overflow: hidden;
  width: 200px;
  max-width: 300px;
  margin-left: 15px;
  margin-right: 15px;
  display: inline-block;
  position: relative;

  p {
    position: absolute;
    top: 50%;
    font-weight: 700;
    font-size: 16px;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    max-width: 60%;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.line-chart {
  position: relative;
  .bg {
    position: absolute;
    height: calc(100% - 32px);
    width: 100%;
    bottom: 28px;
    left: 0;
    // background: rgb(1, 174, 83);
    // background: linear-gradient(
    //   0deg,
    //   rgba(253, 6, 0, 1) 0%,
    //   rgba(255, 191, 10, 1) 25%,
    //   rgba(255, 255, 4, 1) 50%,
    //   rgba(1, 174, 83, 1) 75%
    // );
    z-index: 1;
  }
  canvas {
    z-index: 999;
  }
}
.pos-z {
  position: relative;
  z-index: 999;
}

.header-links {
  a {
    margin-right: 10px;
    color: white;
    transition: 0.5s;
    &:hover {
      color: grey;
    }
  }
}

.image-wrapper {
  position: relative;
  padding-top: 100%;
  img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.loader-wrapper {
  position: absolute;
  background-color: black;
  transition: 0.5s;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  opacity: 0;
  z-index: -1;
  &.show {
    opacity: 1;
    z-index: 999999;
  }
}

.switch-wrapper {
  position: relative;
}

.switch-wrapper > div {
  position: a bsolute;
  width: 100%;
}
.fade-enter {
  opacity: 0;
  z-index: 1;
}
.switch-wrapper {
  position: relative;
}

.switch-wrapper > div {
  position: absolute;
}

.route-wrapper {
  position: relative;
}

.route-wrapper > div {
  position: absolute;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.line {
  height: 3px;
  width: 100%;
  display: block;
  background-color: black;
  margin-bottom: 15px;
}

ol {
  padding-left: 15px;
  li {
    padding-left: 7.5px;
    padding-bottom: 15px;
  }
}
.single-dianostics-item {
  img {
    height: 75px;
    width: 75px;
    object-fit: contain;
    margin-right: 15px;
    display: inline-block;
  }
  p {
    display: inline-block;
  }
}

@media (max-width: 600px) {
  html {
    font-size: 16px;
  }
  h1 {
    font-size: 2rem;
  }
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.text-gray {
  color: gray;
}
.table {
  tr {
    border-color: transparent !important;
  }
  td,
  th {
    border: 1px solid black !important;
    padding: 7.5px;
    text-align: center;
    // color: white !important;
  }
  th {
    width: 30px !important;
    padding: 7.5px 0px;
    border-color: transparent;
    color: black !important;
  }
  .numbers {
    td {
      border-left-color: transparent;
      border-right-color: transparent;
      border-top-color: transparent;
      color: black !important;
    }
  }
}
